import type { AppProps } from 'next/app'
import { GoogleTagManager } from '@next/third-parties/google'
import Head from 'next/head'

import '@csstools/normalize.css'
import '~/base/base.css'

function MyApp({ Component, pageProps }: AppProps) {
  const title = `${
    pageProps.story?.name || 'Genussfestival Vaduz – 6. – 14. September 2025'
  } | Genussfestival Vaduz – 6. – 14. September 2025`

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta
          name="description"
          content="Genussfestival Vaduz, vom 6. – 14. September 2025. Auf dem Rathausplatz, in Restaurants und Geschäften von Vaduz."
        />

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="theme-color" content="#000000" />

        {/* OG-Tags */}
        <meta property="og:locale" content="de" key="og:locale" />
        <meta property="og:type" content="website" key="og:type" />
        <meta property="og:url" content="https://www.genussfestival.li/" key="og:url" />
        <meta property="og:title" content="Genussfestival Vaduz – 6. – 14. September 2025" key="og:title" />
        <meta
          property="og:description"
          content="Genussfestival Vaduz, vom 6. – 14. September 2025. Auf dem Rathausplatz, in Restaurants und Geschäften von Vaduz."
          key="og:description"
        />
        <meta property="og:image" content="https://www.genussfestival.li/share.jpg" key="og:image" />

        <link as="style" href="/fonts/fonts.css" />
      </Head>
      <Component key={pageProps.story?.id} {...pageProps} />
      <GoogleTagManager gtmId="GTM-T5N9WRMW" />
    </>
  )
}

export default MyApp
